@import 'src/style';

.container {
  display: grid;
  grid-auto-flow: row;
  padding: $large;
  gap: $small/2;
  overflow-y: auto;
  // max-height: 51vh;
}

.question {
  padding: $small;
}