/* font-family */
$font-menu: Roboto;
$font-general: Roboto;

/* font-weight */
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

/* font-size */
$font-size-mini: 10px;
$font-size-small: 12px;
$font-size-medium: 14px;
$font-size-regular: 16px;
$font-size-large: 20px;
$font-size-huge: 24px;
$font-size-gigantic: 34px;

/* font line-height */
$line-height-accent-primary: 40px;
$line-height-header-primary: 32px;
$line-height-header-secondary: 26px;
$line-height-subtitle-primary: 24px;
$line-height-subtitle-secondary: 24px;
$line-height-body-primary: 28px;
$line-height-body-secondary: 20px;
$line-height-button: 19px;
$line-height-caption: 16px;
$line-height-overline: 16px;

/* letter-space */
$letter-space-body-primary: 0.5px;
$letter-space-body-secondary: 0.25px;
$letter-space-accent-primary: 0.25px;
$letter-space-header-primary: 0px;
$letter-space-header-secondary: 0.15px;
$letter-space-subtitle-primary: 0.15px;
$letter-space-subtitle-secondary: 0.1px;
$letter-space-button: 1.25px;
$letter-space-caption: 0.4px;
$letter-space-body-primary-x: 0.44px;
$letter-space-overline: 1.5px;