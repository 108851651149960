@import 'src/style';

.paper {
  // max-height: 87vh;
  // min-height: 87vh;
}

.dataAccordeon {
  transition-duration: 0s !important;
}
.container {
  display: grid;
  grid-auto-flow: row;
  padding: $large;
  gap: $small/2;
  overflow-y: auto;
  // max-height: 51vh;
}

.question {
  padding: $small;
}

.controlsContainer {
  display: grid;
  grid-auto-flow: column;
  gap: $normal;
  grid-template-columns: 2fr 1fr;
}
