@import 'src/style';

.container {
  display: grid;
  grid-auto-flow: row;
  padding: $large;
  gap: $normal;
}

.space {
  padding: $normal;
  
  &:hover {
    background-color: rgb(233, 233, 233);
    cursor: pointer;
  }
}