@import 'src/style';

.container {
  display: grid;
  gap: $large;
  grid-auto-flow: row;
  padding: $large;
}

.result {
  display: grid;
}

.management {
  display: grid;
}