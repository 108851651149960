/* grid */
$atom: 4px;
$small: $atom * 2;
$normal: $atom * 4;
$large: $atom * 6;

/* layers */
$z-first-layer: 10;
$z-tooltip-layer: 20;
$z-popup-layer: 50;
$z-above-tooltip-layer: 75;
$z-modal-first-layer: 100;
$z-modal-above-all-layer: 10000;
$z-modal-preloader: 1000;
$z-main-menu: 1500;
$z-main-header: 1900;
$z-toasts: 2000;